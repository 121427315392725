const PROGRAMS = {
  title: "Programs",
  dataPacks: ["STUDIES"],
  local: false,
  taxonomy: false,
  fromPage: true,
  translate: true,
  clone: null,
  defaultValues: null,
  schema: {
    templates: ["ProgramDetail"],
    searchFrom: [],
    fields: [
      {
        key: "title",
        title: "Title",
        type: "TextField",
        from: "title",
        mandatory: true,
        humanReadable: true,
      },
      {
        key: "abstract",
        title: "Abstract",
        type: "RichText",
        html: true,
        from: "abstract",
        mandatory: true,
        humanReadable: true,
      },
      {
        key: "shortName",
        title: "Short name",
        type: "TextField",
        from: "shortName",
        hideable: true,
        html: true,
        humanReadable: true,
      },
      {
        key: "newElement",
        type: "UniqueCheck",
        title: "",
        options: [
          {
            title: "New",
          },
        ],
        from: "newElement",
      },
      {
        key: "weight",
        title: "Weight",
        from: "weight",
        type: "NumberField",
        indexable: true,
      },
      {
        key: "dateTime",
        title: "Date",
        from: "dateTime",
        type: "DateField",
        indexable: true,
      },
      {
        key: "duration",
        title: "Duration",
        type: "AsyncCheckGroup",
        from: "categories.duration",
        source: "DURATION",
      },
      {
        key: "format",
        title: "Format",
        type: "AsyncCheckGroup",
        from: "categories.format",
        source: "FORMAT",
      },
      {
        key: "intake",
        title: "Intake",
        type: "AsyncCheckGroup",
        from: "categories.intake",
        source: "INTAKE",
      },
      {
        key: "language",
        title: "Language",
        type: "AsyncCheckGroup",
        from: "categories.language",
        source: "LANGUAGE",
      },
      {
        key: "location",
        title: "Location",
        type: "AsyncCheckGroup",
        from: "categories.location",
        source: "LOCATION",
      },
      {
        key: "modeStudy",
        title: "Mode of Study",
        type: "AsyncCheckGroup",
        from: "categories.modeStudy",
        source: "MODE_STUDY",
      },
      {
        key: "pathWaysData",
        title: "Pathways",
        type: "AsyncCheckGroup",
        from: "categories.pathWaysData",
        source: "PATHWAYS",
      },
      {
        key: "pathwaysExecutiveData",
        title: "Pathways Executive Education",
        type: "AsyncCheckGroup",
        from: "categories.pathwaysExecutiveData",
        source: "PATHWAYS_EXECUTIVE",
      },
      {
        key: "programType",
        title: "Program Type",
        type: "AsyncCheckGroup",
        from: "categories.programType",
        source: "PROGRAM_TYPE",
      },
      {
        key: "schoolsData",
        title: "Schools",
        type: "AsyncCheckGroup",
        from: "categories.schoolsData",
        source: "SCHOOLS",
        showList: true,
        indexable: true,
      },
      {
        key: "units",
        title: "Unit",
        type: "AsyncCheckGroup",
        from: "categories.units",
        source: "UNIT",
      },
      {
        key: "weightCategory",
        title: "Weight Category",
        type: "AsyncCheckGroup",
        from: "categories.weightCategory",
        source: "WEIGHT_CATEGORY",
      },
      {
        key: "yearsExperience",
        title: "Years of Experience",
        type: "AsyncCheckGroup",
        from: "categories.yearsExperience",
        source: "YEARS_EXPERIENCE",
      },
      {
        key: "imageFromHero",
        title: "Image from Hero",
        type: "ComponentContainer",
        from: "imageFromHero",
      },
      {
        key: "subtitleFromHero",
        title: "Subtitle from Hero",
        type: "TextField",
        from: "subtitleFromHero",
      },
      {
        key: "logosFromHero",
        title: "Logos from Hero",
        type: "ComponentArray",
        from: "logosFromHero",
      },
      {
        key: "quickFacts",
        title: "Quick Facts from Quick Facts Section",
        type: "ComponentContainer",
        from: "quickFacts",
      },
      {
        title: "Contact us",
        type: "ComponentContainer",
        whiteList: ["LinkModal"],
        key: "contactUsButton",
        hideable: true,
        from: "contactUsButton",
      },
      {
        key: "applyButton",
        title: "apply from Program Tabs",
        type: "ComponentContainer",
        from: "applyButton",
      },
      {
        key: "downloadBrochureButton",
        title: "download brochure from Program Tabs",
        type: "ComponentContainer",
        from: "downloadBrochureButton",
      },
      {
        key: "forPeopleWho",
        from: "forPeopleWho",
        title: "For people who...",
        html: true,
        type: "RichText",
        humanReadable: true,
      },
      {
        key: "toBecome",
        from: "toBecome",
        title: "To become",
        type: "RichText",
        humanReadable: true,
      },
      {
        key: "reasonsWhy",
        from: "reasonsWhy",
        title: "Reasons why",
        type: "RichText",
        humanReadable: true,
      },
      {
        key: "beyondTheProgram",
        from: "beyondTheProgram",
        title: "Beyond the program",
        type: "RichText",
        humanReadable: true,
      },
      {
        key: "residentialPeriods",
        from: "residentialPeriods",
        title: "Residential periods",
        type: "RichText",
        humanReadable: true,
      },
      {
        type: "ArrayFieldGroup",
        title: "Study plan",
        key: "studyPlan",
        name: "Study plan",
        from: "studyPlan",
      },
      {
        key: "locationImage",
        title: "Location image",
        type: "ImageField",
        from: "locationImage",
      },
      {
        title: "Location button",
        type: "ComponentContainer",
        whiteList: ["Link"],
        key: "locationButton",
        hideable: true,
        from: "locationButton",
      },
      {
        title: "Video",
        type: "UrlField",
        key: "video",
        from: "video",
      },
      {
        title: "Blogs",
        type: "ReferenceField",
        sources: [{ structuredData: "BLOGS" }],
        key: "blogsData",
        from: "blogsData",
      },
    ],
  },
}

export { PROGRAMS }
