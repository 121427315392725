import { allModulesWhitelist } from "@helpers/schemas"
import {
  SpecializationDetail,
  SpecializationDetail2x,
} from "@thumbnails/Templates"
import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"

// Defaults from modules
import HeroSpecialization from "@schemas/modules/HeroSpecialization"
import ProgramQuickFacts from "@schemas/modules/ProgramQuickFacts"

export default {
  schemaType: "template",
  displayName: "Specialization Detail",
  component: "SpecializationDetail",
  dataPacks: ["SPECIALIZATIONS"],
  type: { label: "Specializations", value: "specializations", mode: "detail" },
  config: [
    {
      key: "online",
      type: "UniqueCheck",
      helptext: "Corresponds to Online and Virtual program.",
      options: [
        {
          title: "Online",
        },
      ],
    },
    {
      key: "durationDataOnline",
      type: "MultiCheckSelect",
      title: "Online Duration",
      placeholder: "Select a Duration",
      source: "DURATION",
    },
    {
      key: "onsite",
      type: "UniqueCheck",
      helptext: "Corresponds to Face to Face and Presencial program.",
      options: [
        {
          title: "Onsite",
        },
      ],
    },
    {
      key: "durationDataOnsite",
      type: "MultiCheckSelect",
      title: "Onsite Duration",
      placeholder: "Select a Duration",
      source: "DURATION",
    },
    {
      key: "locationDataOnsite",
      type: "MultiCheckSelect",
      title: "Onsite Location",
      placeholder: "Select a Location",
      source: "LOCATION",
    },
    {
      key: "blended",
      type: "UniqueCheck",
      helptext:
        "Corresponds to Blended, Face to Face + Virtual and Presencial + Virtual program.",
      options: [
        {
          title: "Blended",
        },
      ],
    },
    {
      key: "durationDataBlended",
      type: "MultiCheckSelect",
      title: "Blended Duration",
      placeholder: "Select a Duration",
      source: "DURATION",
    },
    {
      key: "locationDataBlended",
      type: "MultiCheckSelect",
      title: "Blended Location",
      placeholder: "Select a Location",
      source: "LOCATION",
    },
  ],
  content: [
    {
      key: "newElement",
      type: "UniqueCheck",
      title: "",
      options: [
        {
          title: "New",
        },
      ],
      helptext: "This badge will be shown on hero section and distributor card",
    },
    {
      key: "badge",
      title: "Badge",
      type: "TextField",
      mandatory: false,
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      whiteList: ["HeroSpecialization"],
      key: "heroSection",
      hideable: false,
    },
    {
      title: "Call to Action Buttons",
      type: "FieldGroup",
      key: "ctas",
      fields: [
        {
          title: "Download brochure button",
          type: "ComponentContainer",
          whiteList: ["DescriptionButton"],
          key: "downloadBrochureButton",
          hideable: true,
          helptext: "Shown on hero section",
        },
        {
          title: "Apply button",
          type: "ComponentContainer",
          whiteList: ["DescriptionButton"],
          key: "applyButton",
          hideable: true,
          helptext: "Shown on hero section",
        },
        {
          title: "Contact us button",
          type: "ComponentContainer",
          whiteList: ["LinkModal"],
          key: "contactUsButton",
          hideable: true,
          helptext: "It can be shown on distributors/list",
        },
      ],
    },
    {
      title: "Abstract",
      type: "RichText",
      html: true,
      key: "abstract",
      hideable: true,
      mandatory: true,
      validators: { maxChar: 240 },
      helptext: "Shown on the distributor card",
      humanReadable: true,
    },
    {
      key: "parentProgram",
      title: "Parent program of the specialization",
      placeholder: "Parent program of the specialization",
      type: "AsyncSelect",
      source: "PROGRAMS",
      helptext:
        "IMPORTANT to select canonical site before the parent program to display it correctly.",
    },
    {
      key: "image",
      title: "Background image if no parent element",
      type: "ImageField",
      mandatory: false,
      hideable: true,
    },
    {
      title: "Veil percentage",
      type: "SliderField",
      key: "veil",
    },
    {
      key: "categories",
      title: "Select Categories",
      type: "MultiCheckSelectGroup",
      filled: true,
      note: "Go to the Category or Global Data sections to create as many as you need.",
      elements: [
        {
          key: "duration",
          placeholder: "Duration",
          source: "DURATION",
        },
        {
          key: "format",
          placeholder: "Format",
          source: "FORMAT",
        },
        {
          key: "intake",
          placeholder: "Intake",
          source: "INTAKE",
        },
        {
          key: "language",
          placeholder: "Language",
          source: "LANGUAGE",
        },
        {
          key: "location",
          placeholder: "Location",
          source: "LOCATION",
        },
        {
          key: "modeStudy",
          placeholder: "Mode of Study",
          source: "MODE_STUDY",
        },
        {
          key: "pathWaysData",
          placeholder: "Pathways",
          source: "PATHWAYS",
        },
        {
          key: "pathwaysExecutiveData",
          placeholder: "Pathways Executive",
          source: "PATHWAYS_EXECUTIVE",
        },
        {
          key: "programType",
          placeholder: "Program Type",
          source: "PROGRAM_TYPE",
        },
        {
          key: "schoolsData",
          placeholder: "Schools",
          source: "SCHOOLS",
        },
        {
          key: "units",
          placeholder: "Unit",
          source: "UNIT",
        },
        {
          key: "weightCategory",
          placeholder: "Weight Category",
          source: "WEIGHT_CATEGORY",
        },
        {
          key: "yearsExperience",
          placeholder: "Years of Experience",
          source: "YEARS_EXPERIENCE",
        },
      ],
    },
    {
      title: "Date",
      type: "DateField",
      mandatory: true,
      key: "dateTime",
      helptext: "This date will be used to sort in distributors and lists.",
    },
    {
      key: "weight",
      title: "Weight",
      type: "TextField",
      indexable: true,
      helptext: "This field will be used to sort in distributors and lists.",
    },
    {
      title: "Quick Facts Section",
      type: "ComponentArray",
      maxItems: 1,
      whiteList: ["ProgramQuickFacts"],
      key: "specializationQuickFacts",
    },
    {
      title: "Related Content",
      type: "ComponentArray",
      whiteList: allModulesWhitelist,
      key: "relatedContent",
    },
  ],

  default: {
    type: "template",
    templateType: "SpecializationDetail",
    badge: "Specialization",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ ...HeroSpecialization.default }],
      sectionPosition: 4,
    },
    abstract:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    image: {},
    veil: 50,
    dateTime: "",
    weight: "9999",
    downloadBrochureButton: {
      component: "DescriptionButton",
      style: "secondary",
      showLeftIcon: false,
      showRigthIcon: true,
      rightIcon: "aDownload",
      auxText: "Auxiliar text",
      link: {
        text: "Download Brochure",
        linkType: "url",
        url: {
          href: null,
          linkTo: null,
          linkToURL: null,
          newTab: true,
          noFollow: false,
        },
        modal: {
          requestInfo: {
            component: "RequestInfo",
          },
          floatingCTAMail: {
            component: "FloatingCTAMail",
          },
          floatingCTALightbox: {
            component: "FloatingCTALightbox",
          },
          floatingCTAVideo: {
            component: "FloatingCTAVideo",
          },
        },
      },
    },
    applyButton: {
      component: "DescriptionButton",
      style: "primary",
      showLeftIcon: false,
      showRigthIcon: true,
      rightIcon: "higher",
      auxText: "Auxiliar text",
      link: {
        text: "Apply",
        linkType: "url",
        url: {
          href: null,
          linkToURL: null,
          linkTo: null,
          newTab: false,
          noFollow: false,
        },
        modal: {
          requestInfo: {
            component: "RequestInfo",
          },
          floatingCTAMail: {
            component: "FloatingCTAMail",
          },
          floatingCTALightbox: {
            component: "FloatingCTALightbox",
          },
          floatingCTAVideo: {
            component: "FloatingCTAVideo",
          },
        },
      },
    },
    contactUsButton: {
      component: "LinkModal",
      style: "primary",
      link: {
        text: "Contact us",
        linkType: "url",
        url: {
          href: null,
          linkToURL: null,
          linkTo: null,
          newTab: false,
          noFollow: false,
        },
        modal: {
          requestInfo: {
            component: "RequestInfo",
          },
          floatingCTAMail: {
            component: "FloatingCTAMail",
          },
          floatingCTALightbox: {
            component: "FloatingCTALightbox",
          },
          floatingCTAVideo: {
            component: "FloatingCTAVideo",
          },
        },
      },
    },
    specializationQuickFacts: {
      component: "Section",
      name: "Quick Facts Section",
      modules: [{ ...ProgramQuickFacts.default }],
      sectionPosition: 4,
    },
    relatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [
        {
          component: "IntroForm",
          title: {
            content: "Título",
            tag: "h1",
          },
          anchorID: null,
          theme: "default",
          subtitle:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium.",
          detail:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium.",
          elements: [],
          componentModules: {
            component: "IntroFormElement",
            formTitle: "Title",
            formImage: {
              component: "Image",
              ...DEFAULT_IMAGE_COMPONENT,
            },
          },
        },
      ],
      sectionPosition: 4,
    },
  },
  thumbnails: {
    "1x": SpecializationDetail,
    "2x": SpecializationDetail2x,
  },
}
