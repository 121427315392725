import { getThemeOptions } from "@helpers/schemas"
import { CardCollection, CardCollection2x } from "@thumbnails/Modules"
import {
  CardCollectionLayout1,
  CardCollectionLayout2,
  CardCollectionLayout3,
  CardCollectionLayout4,
  CardCollectionLayout5,
} from "@layouts"
import {
  BasicCard,
  BasicBoxedCard,
  BasicIconCard,
  IconCard,
  ImageCard,
  IconCenteredCard,
  LogoCard,
} from "@thumbnails/Components"

export default {
  schemaType: "module",
  displayName: "Card Collection",
  component: "CardCollection",
  category: "collections",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Cards",
          type: "ComponentArray",
          elementUniqueSelection: true,
          mandatory: true,
          maxItems: null,
          key: "elements",
          whiteList: [
            "BasicCard",
            "BasicBoxedCard",
            "BasicIconCard",
            "ImageCard",
            "IconCard",
            "IconCenteredCard",
            "LogoCard",
          ],
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
        {
          title: "Cards titles link",
          type: "RadioGroup",
          key: "titlesLink",
          mandatory: false,
          options: [
            {
              value: true,
              title: "Yes",
              name: "yes",
            },
            {
              value: false,
              title: "No",
              name: "no",
            },
          ],
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "L001",
              img: CardCollectionLayout1,
            },
            {
              value: "L002",
              img: CardCollectionLayout2,
            },
            {
              value: "L003",
              img: CardCollectionLayout3,
            },
            {
              value: "L004",
              img: CardCollectionLayout4,
            },
            {
              value: "L005",
              img: CardCollectionLayout5,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
        {
          title: "Card Style",
          key: "kind",
          type: "VisualUniqueSelection",
          elementUniqueSelection: true,
          options: [
            {
              value: "BasicCard",
              img: BasicCard,
            },
            {
              value: "BasicBoxedCard",
              img: BasicBoxedCard,
            },
            {
              value: "BasicIconCard",
              img: BasicIconCard,
            },
            {
              value: "ImageCard",
              img: ImageCard,
            },
            {
              value: "IconCard",
              img: IconCard,
            },
            {
              value: "IconCenteredCard",
              img: IconCenteredCard,
            },
            {
              value: "LogoCard",
              img: LogoCard,
            },
          ],
          columns: 3,
        },
        {
          key: "decoration",
          type: "UniqueCheck",
          options: [
            {
              title: "Show decoration",
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: "CardCollection",
    anchorID: null,
    theme: "defaultAlt",
    verticalSpacing: "medium",
    layout: "L002",
    kind: "BasicCard",
    decoration: false,
    title: { content: "Title", tag: "h2" },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
    detail: null,
    elements: [
      {
        component: "BasicCard",
        title: {
          content: "Title",
          tag: "h4",
        },
        subtitle: "Lorem Ipsum",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
        auxInfo: "Auxiliar Information",
        link: {
          component: "LinkModal",
          style: "link",
          link: {
            text: "Link",
            linkType: "url",
            url: {
              href: null,
              linkToURL: null,
              linkTo: null,
              newTab: false,
              noFollow: false,
            },
            modal: {
              requestInfo: {
                component: "RequestInfo",
              },
              floatingCTAMail: {
                component: "FloatingCTAMail",
              },
              floatingCTALightbox: {
                component: "FloatingCTALightbox",
              },
              floatingCTAVideo: {
                component: "FloatingCTAVideo",
              },
            },
          },
        },
        link2: {
          component: "LinkModal",
        },
        media: {
          image: {
            component: "LinkableImage",
          },
          video: {
            component: "Video",
          },
        },
      },
      {
        component: "BasicCard",
        title: {
          content: "Title",
          tag: "h4",
        },
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
        auxInfo: "Auxiliar Information",
        link: {
          component: "LinkModal",
          style: "link",
          link: {
            text: "Link",
            linkType: "url",
            url: {
              href: null,
              linkToURL: null,
              linkTo: null,
              newTab: false,
              noFollow: false,
            },
            modal: {
              requestInfo: {
                component: "RequestInfo",
              },
              floatingCTAMail: {
                component: "FloatingCTAMail",
              },
              floatingCTALightbox: {
                component: "FloatingCTALightbox",
              },
              floatingCTAVideo: {
                component: "FloatingCTAVideo",
              },
            },
          },
        },
        link2: {
          component: "LinkModal",
        },
        media: {
          image: {
            component: "LinkableImage",
          },
          video: {
            component: "Video",
          },
        },
      },
      {
        component: "BasicCard",
        title: {
          content: "Title",
          tag: "h4",
        },
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
        auxInfo: "Auxiliar Information",
        link: {
          component: "LinkModal",
          style: "link",
          link: {
            text: "Link",
            linkType: "url",
            url: {
              href: null,
              linkToURL: null,
              linkTo: null,
              newTab: false,
              noFollow: false,
            },
            modal: {
              requestInfo: {
                component: "RequestInfo",
              },
              floatingCTAMail: {
                component: "FloatingCTAMail",
              },
              floatingCTALightbox: {
                component: "FloatingCTALightbox",
              },
              floatingCTAVideo: {
                component: "FloatingCTAVideo",
              },
            },
          },
        },
        link2: {
          component: "LinkModal",
        },
        media: {
          image: {
            component: "LinkableImage",
          },
          video: {
            component: "Video",
          },
        },
      },
    ],
    groupingLink: {
      component: "Link",
    },
    titlesLink: false,
  },
  thumbnails: {
    "1x": CardCollection,
    "2x": CardCollection2x,
  },
}
