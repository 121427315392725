import React, { useState } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { Box, Text } from "@sqymagma/elements"
import { themeGet as theme } from "@styled-system/theme-get"
import Icons from "@icons"
import { LinkWrapper } from "@components"
import MobileSubMenu from "./MobileSubMenu"
import { useI18n } from "@hooks"

const PrimaryLink = styled(Box)`
  cursor: pointer;
`

const Line = styled.div`
  line-height: 0;
  display: block;
  height: 1px;
  width: 100%;
  background-color: ${theme("colors.text01")};
  margin: ${p => (p.$primaryLink && p.$isOpen ? "24px 0 0 0" : "24px 0")};
`

const ContentWrapper = styled(Box)`
  overflow: hidden;
  height: auto;
  transition: ${p =>
    !p.isOpen
      ? "max-height 0.5s cubic-bezier(0, 1, 0, 1), opacity 0.5s ease"
      : "max-height 0.5s cubic-bezier(1, 0, 1, 0), opacity 1.5s ease"};
  max-height: ${p => (!p.$isOpen ? 0 : "9999px")};
  opacity: ${p => (!p.$isOpen ? 0 : "1")};
`

const LinkWrapperCustom = styled(LinkWrapper)`
  cursor: pointer;
  padding: 8px 0;
`

const MobileMenuItem = ({ item, itemVariants, active, isActiveLink }) => {
  const { getNestedTranslation: t } = useI18n()
  const goToLabel = t("modules.header.goTo", "Go to")
  const pageLabel = t("modules.header.page", "page")

  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <motion.li variants={itemVariants}>
        <PrimaryLink
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          onClick={() => setOpen(!isOpen)}
        >
          <Text
            textStyle="headings"
            color="link01"
            active={active}
            data-testid="textItem"
          >
            {item.label}
          </Text>
          {item?.children?.length ? (
            <Box height={{ default: "16px", m: "24px" }}>
              <Icons
                display="block"
                name={isOpen ? "remove" : "add1"}
                fill="text01"
                height={{ default: "16px", m: "24px" }}
                cursor="pointer"
                data-testid="menuItem"
                aria-hidden={true}
              />
            </Box>
          ) : null}
        </PrimaryLink>
        <Line $isOpen={isOpen} $primaryLink={item?.url && true} />
      </motion.li>

      {/* Sub menu */}
      {item?.children?.length ? (
        <ContentWrapper as="li" $isOpen={isOpen}>
          {item?.url && (
            <LinkWrapperCustom
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              mb="m"
              bg="ui03"
              title={item?.label}
              href={item?.url?.href}
              linkToURL={item?.url?.linkToURL}
              newTab={item?.url?.newTab}
              noFollow={item?.url?.noFollow}
            >
              <Text textStyle="subtitle03" color="link01">
                {`${goToLabel} ${item.label} ${pageLabel}`}
              </Text>
              <Icons name="right" fill="text01" width="24px" mx="xxs" />
            </LinkWrapperCustom>
          )}
          <MobileSubMenu menuItem={item} isActiveLink={isActiveLink} />
        </ContentWrapper>
      ) : null}
    </>
  )
}

export default MobileMenuItem
