import { getThemeOptions } from "@helpers/schemas"
import { Positions, Positions2x } from "@thumbnails/Modules"
import { PositionsLayout1, PositionsLayout2, PositionsLayout3 } from "@layouts"

export default {
  schemaType: "module",
  displayName: "Positions",
  component: "Positions",
  category: "content",
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          mandatory: true,
          hideable: false,
          default: { tag: "h2", content: "Career Opportunities" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Image",
          type: "ImageField",
          key: "image",
          mandatory: true,
        },
        {
          title: "Positions Cards",
          key: "elements",
          type: "ComponentArray",
          maxItems: 17,
          whiteList: ["PositionsCard"],
          hideable: false,
          mandatory: true,
          helptext:
            "Layout1 view for 17 elements Layout1.\r\n\r\nLayout2 view for 12 elements.\r\n\r\nLayout3 view for 7 elements.",
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "L001",
              img: PositionsLayout1,
            },
            {
              value: "L002",
              img: PositionsLayout2,
            },
            {
              value: "L003",
              img: PositionsLayout3,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentAlt",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
      ],
    },
  ],

  default: {
    component: "Positions",
    anchorID: null,
    theme: "defaultAlt",
    verticalSpacing: "medium",
    layout: "L001",
    title: { content: "Career Opportunities", tag: "h2" },
    image: {
      title: "Positions",
      alt: "PositionsCard",
      url: "https://images.qa.thesaurus.ie.edu/positions-img-08",
    },
    elements: [
      {
        component: "PositionsCard",
        tag: "Tag text",
        image: {
          title: "Positions",
          alt: "PositionsCard",
          url: "https://images.qa.thesaurus.ie.edu/positions-img-01",
        },
      },
      {
        component: "PositionsCard",
        tag: "Tag text",
        image: {
          title: "Positions",
          alt: "PositionsCard",
          url: "https://images.qa.thesaurus.ie.edu/positions-img-02",
        },
      },

      {
        component: "PositionsCard",
        tag: "Tag text",
        image: {
          title: "Positions",
          alt: "PositionsCard",
          url: "https://images.qa.thesaurus.ie.edu/positions-img-03",
        },
      },
      {
        component: "PositionsCard",
        tag: "Tag text",
        image: {
          title: "Positions",
          alt: "PositionsCard",
          url: "https://images.qa.thesaurus.ie.edu/positions-img-04",
        },
      },
      {
        component: "PositionsCard",
        tag: "Tag text",
        image: {
          title: "Positions",
          alt: "PositionsCard",
          url: "https://images.qa.thesaurus.ie.edu/positions-img-05",
        },
      },
      {
        component: "PositionsCard",
        tag: "Tag text",
        image: {
          title: "Positions",
          alt: "PositionsCard",
          url: "https://images.qa.thesaurus.ie.edu/positions-img-06",
        },
      },
      {
        component: "PositionsCard",
        tag: "Tag text",
        image: {
          title: "Positions",
          alt: "PositionsCard",
          url: "https://images.qa.thesaurus.ie.edu/positions-img-07",
        },
      },
      {
        component: "PositionsCard",
        tag: "Tag text",
        image: {
          title: "Positions",
          alt: "PositionsCard",
          url: "https://images.qa.thesaurus.ie.edu/positions-img-09",
        },
      },
      {
        component: "PositionsCard",
        tag: "Tag text",
        image: {
          title: "Positions",
          alt: "PositionsCard",
          url: "https://images.qa.thesaurus.ie.edu/positions-img-10",
        },
      },
      {
        component: "PositionsCard",
        tag: "Tag text",
        image: {
          title: "Positions",
          alt: "PositionsCard",
          url: "https://images.qa.thesaurus.ie.edu/positions-img-11",
        },
      },
      {
        component: "PositionsCard",
        tag: "Tag text",
        image: {
          title: "Positions",
          alt: "PositionsCard",
          url: "https://images.qa.thesaurus.ie.edu/positions-img-12",
        },
      },
      {
        component: "PositionsCard",
        tag: "Tag text",
        image: {
          title: "Positions",
          alt: "PositionsCard",
          url: "https://images.qa.thesaurus.ie.edu/positions-img-13",
        },
      },
      {
        component: "PositionsCard",
        tag: "Tag text",
        image: {
          title: "Positions",
          alt: "PositionsCard",
          url: "https://images.qa.thesaurus.ie.edu/positions-img-14",
        },
      },
      {
        component: "PositionsCard",
        tag: "Tag text",
        image: {
          title: "Positions",
          alt: "PositionsCard",
          url: "https://images.qa.thesaurus.ie.edu/positions-img-15",
        },
      },
      {
        component: "PositionsCard",
        tag: "Tag text",
        image: {
          title: "Positions",
          alt: "PositionsCard",
          url: "https://images.qa.thesaurus.ie.edu/positions-img-16",
        },
      },
      {
        component: "PositionsCard",
        tag: "Tag text",
        image: {
          title: "Positions",
          alt: "PositionsCard",
          url: "https://images.qa.thesaurus.ie.edu/positions-img-17",
        },
      },
      {
        component: "PositionsCard",
        tag: "Tag text",
        image: {
          title: "Positions",
          alt: "PositionsCard",
          url: "https://images.qa.thesaurus.ie.edu/positions-img-18",
        },
      },
    ],
  },
  thumbnails: {
    "1x": Positions,
    "2x": Positions2x,
  },
}
