import { allSectionList, getThemeOptions } from "@helpers/schemas"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import {
  ProgramsPresentationAuto,
  ProgramsPresentationAuto2x,
} from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Programs Presentation Auto",
  component: "ProgramsPresentationAuto",
  category: "studies",
  dataPacks: null,
  sectionList: allSectionList,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Lorem Ipsum" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Show image when no option is selected",
          type: "ConditionalField",
          key: "showImage",
          helptext: "",
          options: [
            {
              name: "Yes",
              value: true,
              title: "Yes",
            },
            {
              name: "No",
              value: false,
              title: "No",
            },
          ],
          fields: [
            {
              condition: true,
              title: "Image",
              type: "ImageField",
              key: "image",
              mandatory: true,
              helptext: "Recommended minimum image size: 1045x598",
            },
          ],
        },
        {
          title: "Program Categories",
          type: "CheckGroup",
          key: "categories",
          options: [
            {
              value: "showDuration",
              title: "Show Durations",
              name: "DURATION",
            },
            {
              value: "showFormat",
              title: "Show Formats",
              name: "FORMAT",
            },
            {
              value: "showIntake",
              title: "Show Intakes",
              name: "INTAKE",
            },
            {
              value: "showLanguage",
              title: "Show Languages",
              name: "LANGUAGE",
            },
            {
              value: "showLocation",
              title: "Show Locations",
              name: "LOCATION",
            },
            {
              value: "showModeStudy",
              title: "Show Modes of Study",
              name: "MODE_STUDY",
            },
            {
              value: "showPathways",
              title: "Show Pathways",
              name: "PATHWAYS",
            },
            {
              value: "showPathwaysExecutive",
              title: "Show Pathways Executive Education",
              name: "PATHWAYS_EXECUTIVE",
            },
            {
              value: "showProgramType",
              title: "Show Program Types",
              name: "PROGRAM_TYPE",
            },
            {
              value: "showSchools",
              title: "Show Schools",
              name: "SCHOOLS",
            },
            {
              value: "showUnits",
              title: "Show Units",
              name: "UNIT",
            },
            {
              value: "showYearsExperience",
              title: "Show Years of Experience",
              name: "YEARS_EXPERIENCE",
            },
          ],
        },
        {
          type: "ReferenceField",
          sources: [
            { structuredData: "PROGRAMS" },
            { structuredData: "SPECIALIZATIONS" },
          ],
          key: "data",
        },
        {
          title: "Content hierarchy for SEO",
          type: "Select",
          key: "cardTitleTag",
          options: [
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "Span" },
          ],
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
        {
          title: "Quiz card",
          type: "ConditionalField",
          key: "quizCard",
          mandatory: true,
          required: true,
          options: [
            {
              name: "Yes",
              value: true,
              title: "Yes",
            },
            {
              name: "No",
              value: false,
              title: "No",
            },
          ],
          fields: [
            {
              title: "Quiz card image",
              type: "ImageField",
              key: "quizCardImage",
              hideable: true,
              condition: true,
            },
            {
              title: "Quiz card title",
              type: "HeadingField",
              key: "quizCardTitle",
              advanced: true,
              hideable: true,
              default: { tag: "h2", content: "Lorem Ipsum" },
              options: [
                { value: "h1", label: "H1" },
                { value: "h2", label: "H2" },
                { value: "h3", label: "H3" },
                { value: "h4", label: "H4" },
                { value: "span", label: "span" },
              ],
              humanReadable: true,
              condition: true,
            },
            {
              title: "Quiz card detail",
              type: "RichText",
              html: true,
              key: "quizCardDetail",
              hideable: true,
              humanReadable: true,
              condition: true,
            },
            {
              title: "Quiz card button",
              type: "ComponentContainer",
              whiteList: ["Link"],
              key: "quizCardLink",
              hideable: true,
              condition: true,
            },
          ],
        },
        {
          title: "Where should the link go?",
          type: "RadioGroup",
          key: "linkDirection",
          mandatory: false,
          helptext: "This only affects to automatic mode.",
          options: [
            {
              value: "pageOfCanonical",
              title: "Page of the canonical site",
              name: "pageOfCanonical",
            },
            {
              value: "pageOfSite",
              title: "Page of this site",
              name: "pageOfSite",
            },
          ],
        },
        {
          title: "Landing page?",
          type: "ConditionalField",
          key: "landingPage",
          options: [
            {
              value: true,
              title: "Yes",
              name: "yes",
            },
            {
              value: false,
              title: "No",
              name: "no",
            },
          ],
          fields: [
            {
              title: "Show additional button",
              type: "RadioGroup",
              key: "additionalButton",
              condition: false,
              options: [
                {
                  name: "no",
                  value: "no",
                  title: "No",
                },
                {
                  name: "apply",
                  value: "apply",
                  title: "Apply",
                },
                {
                  name: "downloadBrochure",
                  value: "downloadBrochure",
                  title: "Download brochure",
                },
                {
                  name: "contactUs",
                  value: "contactUs",
                  title: "Contact us",
                },
              ],
            },
            {
              title: "Same CTA to all programs",
              type: "ComponentContainer",
              whiteList: ["LinkModal"],
              key: "ctaAllPrograms",
              hideable: true,
              condition: true,
            },
          ],
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    verticalSpacing: "medium",
    component: "ProgramsPresentationAuto",
    title: {
      content: "Lorem Ipsum",
      tag: "h1",
    },
    data: {
      mode: "auto",
      sources: [
        {
          structuredData: "PROGRAMS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      order: "alpha",
      quantity: 15,
      preferenceLanguage: false,
    },
    subtitle: null,
    detail: null,
    showImage: true,
    image: { ...DEFAULT_IMAGE_FIELD },
    categories: [],
    quizCard: false,
    cardTitleTag: "h3",
    groupingLink: {
      component: "Link",
      text: "See All Programs",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
    quizCardImage: { ...DEFAULT_IMAGE_FIELD },
    quizCardTitle: {
      content: "Not sure what to study?",
      tag: "h1",
    },
    quizCardSubtitle: "Discover de right program for you.",
    quizCardLink: {
      component: "Link",
      text: "Take a quiz",
      style: "primary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
    linkDirection: "pageOfSite",
    landingPage: false,
    additionalButton: "no",
    ctaAllPrograms: {
      component: "LinkModal",
      style: "primary",
      link: {
        text: "More information",
        url: {
          href: null,
          linkToURL: null,
          linkTo: null,
          newTab: false,
          noFollow: false,
        },
        modal: {
          floatingCTALightbox: {
            component: "FloatingCTALightbox",
          },
        },
      },
    },
    theme: "defaultAlt",
  },
  thumbnails: {
    "1x": ProgramsPresentationAuto,
    "2x": ProgramsPresentationAuto2x,
  },
}
