import { NoThumbnail, NoThumbnail2x } from "@thumbnails"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { getThemeOptions, getBackgroundThemeOptions } from "@helpers/schemas"

export default {
  schemaType: "component",
  displayName: "Mosaic hero card",
  component: "HeroMosaicCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
          hideable: true,
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
        {
          title: "Image or video?",
          type: "ConditionalField",
          key: "imageOrVideo",
          mandatory: true,
          required: true,
          options: [
            {
              value: "image",
              title: "Image",
              name: "image",
            },
            {
              value: "video",
              title: "Video",
              name: "video",
            },
            {
              value: "color",
              title: "Color",
              name: "color",
            },
          ],
          fields: [
            {
              title: "Background Image",
              type: "ComponentContainer",
              whiteList: ["ImageWithoutVeil"],
              key: "backgroundImage",
              helptext: "Recommended minimum image size: 2508x1030",
              hideable: true,
              condition: "image",
            },
            {
              title: "Second image to animated it on desktop",
              type: "ComponentContainer",
              whiteList: ["ImageWithoutVeil"],
              key: "secondBackgroundImage",
              helptext: "Recommended minimum image size: 2508x1030",
              hideable: true,
              condition: "image",
            },
            {
              title: "Video URL",
              type: "TextField",
              key: "video",
              hideable: false,
              condition: "video",
            },
            {
              title: "Image on mobile",
              type: "ComponentContainer",
              whiteList: ["ImageWithoutVeil"],
              key: "imageOnMobile",
              helptext: "Recommended minimum image size: 2508x1030",
              hideable: false,
              condition: "video",
            },
          ],
        },
        {
          title: "Card color",
          key: "cardColor",
          type: "VisualUniqueSelection",
          options: getBackgroundThemeOptions([
            "accent",
            "accentLight",
            "accentMid",
            "accentAlt",
            "defaultAlt",
            "inverse",
          ]),
          columns: 8,
        },
        {
          title: "Veil percentage",
          type: "SliderField",
          key: "veil",
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Title color",
          key: "textColor",
          type: "VisualUniqueSelection",
          options: getBackgroundThemeOptions([
            "defaultAlt",
            "accent",
            "accentAlt",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
        {
          title: "Title size",
          type: "RadioGroup",
          key: "textSize",
          mandatory: true,
          options: [
            {
              name: "big",
              value: "big",
              title: "Big",
            },
            {
              name: "small",
              value: "small",
              title: "Small",
            },
          ],
        },
        {
          title: "Content Position",
          type: "RadioGroup",
          key: "contentPosition",
          mandatory: true,
          options: [
            {
              name: "top",
              value: "top",
              title: "Top",
            },
            {
              name: "center",
              value: "center",
              title: "Center",
            },
            {
              name: "bottom",
              value: "bottom",
              title: "Bottom",
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: "HeroMosaicCard",
    title: { content: "Title", tag: "h4" },
    link: {
      component: "Link",
      text: "Link",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
    imageOrVideo: "image",
    backgroundImage: {
      component: "ImageWithoutVeil",
      file: DEFAULT_IMAGE_FIELD,
    },
    secondBackgroundImage: {
      component: "ImageWithoutVeil",
    },
    imageOnMobile: {
      component: "ImageWithoutVeil",
      file: DEFAULT_IMAGE_FIELD,
    },
    veil: 30,
    cardColor: "accent",
    contentPosition: "bottom",
    textSize: "small",
    textColor: "accent",
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
