import React, { useState } from "react"
import { Box, Flex, Text } from "@sqymagma/elements"
import Icons from "@icons"
import { themeGet as theme } from "@styled-system/theme-get"
import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"
import { LinkWrapper } from "@components"
import Portal from "@elements/Portal"
import { useI18n } from "@hooks"

const SubMenuContainer = styled(Box)`
  display: block;
`

const SubNavList = styled.ul``
const AuxText = styled(Box)`
  margin-bottom: ${theme("spacing.m")};
`

const SubNavItem = styled.li`
  cursor: pointer;
  color: ${theme("colors.inverse01")};
  padding-bottom: ${theme("spacing.xxs")};
  &:last-child {
    ${AuxText} {
      margin-bottom: 0;
    }
  }
`

const LinkContainer = styled(Box)`
  cursor: pointer;
  & > a {
    width: 100%;
  }
`

const SubLinkWrapper = styled(LinkWrapper)`
  display: flex;
  align-items: center;
  justify-content: start;
  transition: 0.3s ease;
  &:hover {
    opacity: 0.7;
  }
`

const SubLinkItem = styled(Text)`
  cursor: pointer;
  font-weight: 500;
  line-height: 18px;
  ${theme("mq.m")} {
    font-size: 16px;
    line-height: 18px;
  }
`

const SubMenuWrapper = styled(motion.div)`
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 560px;
  height: 100vh;
  background-color: ${theme("colors.ui01")};
  padding: ${theme("spacing.s")};
  ${theme("mq.m")} {
    padding: 0 ${theme("spacing.xxl")};
  }
`

const BackToMain = styled(Box)`
  cursor: pointer;
  margin-left: -5px;
`

const LinkWrapperCustom = styled(LinkWrapper)`
  cursor: pointer;
  padding: 8px 0;
`

const Line = styled.div`
  line-height: 0;
  display: block;
  height: 1px;
  width: 100%;
  background-color: ${theme("colors.text01")};
  margin: ${p => (p.$hasLink ? "24px 0 0 0" : "24px 0")};
`

const SubMenu = ({ menuItem, isActiveLink }) => {
  const [subOpen, setSubOpen] = useState(-1)
  const hasSpecialLinks = menuItem?.children.find(subItem => subItem.special)
  const { getNestedTranslation: t } = useI18n()
  const goToLabel = t("modules.header.goTo", "Go to")
  const pageLabel = t("modules.header.page", "page")

  return (
    <SubMenuContainer
      ml={{ default: "s", m: "m" }}
      mt={{ default: "xxs" }}
      mb={{ default: "s", m: "l" }}
    >
      <SubNavList>
        {menuItem?.children?.map((subItem, subIdx) => {
          return (
            !subItem.special && (
              <SubNavItem key={subIdx}>
                <LinkContainer
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb="xxs"
                  style={{
                    opacity: isActiveLink(subItem) ? 0.5 : 1,
                  }}
                  onClick={() => {
                    if (subItem?.children?.length) {
                      setSubOpen(subIdx)
                    }
                  }}
                >
                  {!subItem?.children?.length && subItem?.url ? (
                    <SubLinkWrapper
                      title={subItem?.label}
                      href={subItem?.url?.href}
                      linkToURL={subItem?.url?.linkToURL}
                      newTab={subItem?.url?.newTab}
                      noFollow={subItem?.url?.noFollow}
                    >
                      <SubLinkItem textStyle="headings" color="text01">
                        {subItem.label}
                      </SubLinkItem>
                    </SubLinkWrapper>
                  ) : (
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <SubLinkItem
                        textStyle="headings"
                        color="text01"
                        data-testid="submenuLabel"
                      >
                        {subItem.label}
                      </SubLinkItem>
                      <Icons
                        name="higher"
                        fill="text01"
                        height="24px"
                        data-testid="goToSubmenu"
                        aria-hidden={true}
                      />
                    </Box>
                  )}
                </LinkContainer>
                {subItem?.auxText && (
                  <AuxText>
                    <Text textStyle="tinym" color="text01">
                      {subItem.auxText}
                    </Text>
                  </AuxText>
                )}

                <AnimatePresence>
                  {subItem?.children?.length && subOpen === subIdx && (
                    <Portal selector="#submenu">
                      <SubMenuWrapper
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={{
                          open: {
                            x: 0,
                            transition: {
                              duration: 0.5,
                            },
                          },
                          closed: {
                            x: "100%",
                            transition: {
                              duration: 0.5,
                            },
                          },
                        }}
                      >
                        {/* Back button */}
                        <BackToMain my={{ default: "xl" }}>
                          <Flex
                            alignItems="center"
                            onClick={() => setSubOpen(-1)}
                            css={`
                              cursor: pointer;
                              transition: 0.3s ease;
                              &:hover {
                                opacity: 0.7;
                              }
                            `}
                          >
                            <Icons
                              width={{ default: "16px" }}
                              name="less"
                              fill="text01"
                              mr="4px"
                              aria-hidden={true}
                            />
                            <Text color="text01" textStyle="heading2xs">
                              {t("back", "Back")}
                            </Text>
                          </Flex>
                        </BackToMain>

                        {/* SubSubMenu */}
                        <Box minHeight="calc(100% - 261px)">
                          <Box>
                            <Text textStyle="headings" color="text01">
                              {subItem.label}
                            </Text>
                          </Box>
                          <Line $hasLink={subItem?.url && true} />

                          {subItem?.url && (
                            <LinkWrapperCustom
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-end"
                              mb="m"
                              bg="ui03"
                              title={subItem?.label}
                              href={subItem?.url?.href}
                              linkToURL={subItem?.url?.linkToURL}
                              newTab={subItem?.url?.newTab}
                              noFollow={subItem?.url?.noFollow}
                            >
                              <Text textStyle="subtitle03" color="link01">
                                {`${goToLabel} ${subItem.label} ${pageLabel}`}
                              </Text>
                              <Icons
                                name="right"
                                fill="text01"
                                width="24px"
                                mx="xxs"
                              />
                            </LinkWrapperCustom>
                          )}
                          <Box as="ul" mt={!subItem?.url && "l"}>
                            {subItem?.children?.map((subSubItem, thirdIdx) => (
                              <Box
                                as="li"
                                key={thirdIdx}
                                mb={{ default: "xs" }}
                              >
                                <LinkWrapper
                                  title={subSubItem.label}
                                  href={subSubItem?.url?.href}
                                  linkToURL={subSubItem?.url?.linkToURL}
                                  newTab={subSubItem?.url?.newTab}
                                  noFollow={subSubItem?.url?.newTab}
                                  css={`
                                    cursor: pointer;
                                    transition: 0.3s ease;
                                    &:hover {
                                      opacity: 0.7;
                                    }
                                    opacity: ${isActiveLink(subSubItem)
                                      ? 0.5
                                      : 1};
                                  `}
                                >
                                  <Text color="text01" textStyle="bodyLead">
                                    {subSubItem.label}
                                  </Text>
                                </LinkWrapper>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </SubMenuWrapper>
                    </Portal>
                  )}
                </AnimatePresence>
              </SubNavItem>
            )
          )
        })}
      </SubNavList>
      {hasSpecialLinks && (
        <SubNavList style={{ marginTop: "24px" }}>
          {menuItem?.children?.map((subItem, subIdx) => {
            return (
              subItem.special && (
                <SubNavItem key={subIdx}>
                  <LinkContainer
                    display="flex"
                    justifyContent="start"
                    alignItems="center"
                    width="100%"
                    mb="xxs"
                    style={{
                      opacity: isActiveLink(subItem) ? 0.5 : 1,
                    }}
                  >
                    <SubLinkWrapper
                      title={subItem?.label}
                      href={subItem?.url?.href}
                      linkToURL={subItem?.url?.linkToURL}
                      newTab={subItem?.url?.newTab}
                      noFollow={subItem?.url?.newTab}
                    >
                      <Icons
                        name="question"
                        height="21px"
                        width="21px"
                        fill="white"
                        aria-hidden={true}
                      />
                      <Text textStyle="heading3xs" color="text01" ml="xxs">
                        {subItem.label}
                      </Text>
                    </SubLinkWrapper>
                  </LinkContainer>
                </SubNavItem>
              )
            )
          })}
        </SubNavList>
      )}
    </SubMenuContainer>
  )
}

export default SubMenu
