import React from "react"
import styled, { css } from "styled-components"
import { LinkWrapper } from "@components"
import { apiComponentIsEmpty } from "@helpers"
import { Flex, Inline, Stack, Text, Box } from "@sqymagma/elements"
import { themeGet as theme } from "@styled-system/theme-get"
import Icons from "@icons"
import { motion } from "framer-motion"
import { space } from "styled-system"
import MobileMenuItem from "./MobileMenuItem"
import RecursiveMenu from "./RecursiveMenu"
import HeaderCTAContainer from "./HeaderCTAContainer"
import { usePage, useI18n, useRenderer } from "@hooks"

const LinkItem = styled(Text)`
  cursor: pointer;
  &:focus {
    outline-offset: -5px;
  }
  ${p =>
    p.active &&
    css`
      opacity: 0.5;
    `}
  ${theme("mq.xl")} {
    opacity: 1;
    &:after {
      content: "";
      width: 100%;
      height: 2px;
      background-color: ${p =>
        !p.menuBg
          ? p.unPin
            ? p.theme.colors.inverse02
            : p.theme.colors.inverse01
          : p.unPin
          ? p.theme.colors.inverse02
          : p.theme.colors.inverse02};
      margin-top: 2px;
      transition: 0.4s ease;
      transform: scale(0);
      transform-origin: 50%;
    }
  }
`

const Nav = styled(motion.nav)`
  display: flex;
  flex-direction: column;
  background-color: ${theme("colors.ui01")};
  height: 100vh;
  width: 100%;
  z-index: 9;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 560px;
  padding: ${theme("spacing.l")} ${theme("spacing.xs")} ${theme("spacing.xxxl")};

  ${theme("mq.m")} {
    padding: ${theme("spacing.xxl")} ${theme("spacing.xxl")}
      ${theme("spacing.xxxl")};
  }

  ${theme("mq.xl")} {
    overflow: hidden;
    align-items: center;
    background-color: inherit;
    padding: 0;
    height: auto;
    max-width: 100%;
    margin-left: auto;
  }

  ${space}
`

const DesktopNavList = styled.ul`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`

const MobileNavList = styled(motion.ul)`
  display: flex;
  flex-direction: column;
`

const NavItem = styled.li`
  ${theme("mq.xl")} {
    &:hover {
      ${LinkItem} {
        &:after {
          transform: scale(1);
        }
      }
    }
    &:focus {
      ${LinkItem} {
        &:after {
          transform: scale(1);
        }
      }
    }
    &:last-child {
      span {
        padding-right: 0;
      }
    }
  }
`

const NavContainer = ({
  isMobile,
  socials,
  mainMenu,
  variant,
  unPin,
  listVariants,
  itemVariants,
  navVariants,
  externalLinks,
  searchLink,
  primaryLink,
  secondaryLink,
  customSetMenuBg,
  menuBg,
  pageLanguages,
  languageNames,
  languageId,
}) => {
  const links = [primaryLink, secondaryLink]
  const hasNoLinks =
    !apiComponentIsEmpty(primaryLink) || !apiComponentIsEmpty(primaryLink)

  const { fullUrl } = usePage()

  const { getNestedTranslation: t } = useI18n()
  const open = t("icons.ariaLabel.open", "Open")

  const isActiveLink = link => {
    const linkUrl = link?.url?.linkToURL
    const children = link?.children
    let result = false
    result = linkUrl === fullUrl
    if (!result && children?.length) {
      result = children.some(child => isActiveLink(child))
    }
    return result
  }

  // Detectar configuración de escala del usuario
  const initialScale = typeof window !== "undefined" && window?.devicePixelRatio
  const { isAX } = useRenderer()

  return (
    <Nav variants={navVariants}>
      {hasNoLinks && isMobile ? (
        <HeaderCTAContainer
          hs={{ default: 0, m: "s" }}
          display="flex"
          links={links}
          menuBg={menuBg}
        />
      ) : (
        <HeaderCTAContainer
          hs={{ default: 0, m: "xs" }}
          display="flex"
          links={links}
          variant={variant}
          menuBg={menuBg}
          unPin={unPin}
        />
      )}
      {/* Desktop and Mobile Menu */}
      {isMobile ? (
        <MobileNavList variants={listVariants}>
          {mainMenu?.elements &&
            mainMenu.elements.map((item, idx) => {
              return (
                <MobileMenuItem
                  key={idx}
                  item={item}
                  active={isActiveLink(item)}
                  isActiveLink={isActiveLink}
                  itemVariants={itemVariants}
                />
              )
            })}
        </MobileNavList>
      ) : (
        <DesktopNavList role="menubar">
          {mainMenu?.elements &&
            mainMenu.elements.map((item, idx) => {
              const linkItem = (
                <LinkItem
                  menuBg={menuBg?.open}
                  px={{ defautl: 0, xl: "xs" }}
                  active={isActiveLink(item)}
                  textStyle="tiny2xl"
                  unPin={unPin}
                  color={{
                    default: "link01",
                    xl:
                      variant === "transparent" && !menuBg?.open
                        ? unPin
                          ? "text01"
                          : "inverse01"
                        : "text01",
                  }}
                >
                  {item.label}
                </LinkItem>
              )
              return (
                <NavItem
                  key={idx}
                  id={`navItem-${idx}`}
                  variants={itemVariants}
                  role="menuitem"
                  aria-haspopup="true"
                  tabindex="0"
                  onMouseEnter={() => customSetMenuBg(true, idx)}
                  onKeyDown={event => {
                    if (event.key === "ArrowRight") {
                      customSetMenuBg(true, idx)
                    }
                    if (event.key === "ArrowLeft" || event.key === "Escape") {
                      customSetMenuBg(false, idx)
                    }
                  }}
                >
                  {item?.url === null ? (
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      tabindex="0"
                      mt={
                        isAX && initialScale === 2
                          ? "3.5px"
                          : isAX && initialScale === 1
                          ? "1.5px"
                          : !isAX && initialScale === 2
                          ? "2px"
                          : !isAX && initialScale === 1
                          ? "0.5px"
                          : "0px"
                      }
                    >
                      {linkItem}
                    </Flex>
                  ) : (
                    <Box
                      css={`
                        a {
                          display: inline-block;
                          position: relative;
                          width: 100%;
                          height: 100%;
                          margin-bottom: 5px;
                          margin-right: 10px !important;
                        }
                        :focus-visible::after {
                          content: "";
                          position: absolute;
                          top: 0;
                          left: 0;
                          right: 0;
                          bottom: 0;
                          border: 5px solid
                            ${p => p.theme.colors.interactive01Focus};
                        }
                      `}
                    >
                      <LinkWrapper
                        onKeyDown={event =>
                          event.key === "Enter" && isActiveLink(item)
                        }
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        title={item?.label}
                        href={item?.url?.href}
                        linkToURL={item?.url?.linkToURL}
                        newTab={item?.url?.newTab}
                        noFollow={item?.url?.noFollow}
                      >
                        {linkItem}
                      </LinkWrapper>
                    </Box>
                  )}
                </NavItem>
              )
            })}
        </DesktopNavList>
      )}
      {/* Recursive Menu Mobile*/}
      {isMobile && (
        <motion.div
          variants={{
            open: {
              x: 0,
              opacity: 1,
              transition: {
                delay: 1.2,
                y: { stiffness: 1000 },
              },
            },
            closed: {
              x: 300,
              opacity: 0,
              transition: {
                y: { stiffness: 1000 },
              },
            },
          }}
        >
          {/* External Links */}
          <Flex mt={{ default: "m", xl: 0 }} mb={{ defalut: "l", xl: 0 }}>
            {(externalLinks || searchLink) && (
              <RecursiveMenu
                externalLinks={externalLinks}
                searchLink={searchLink}
              />
            )}
          </Flex>
          {/* Icons*/}
          <Flex alignItems="center" display="flex" mb={{ defalut: "l" }}>
            <Inline hs="s" flexShrink="0" mr="l">
              {socials &&
                socials.map((link, idx) => {
                  return (
                    <LinkWrapper
                      key={idx}
                      display="flex"
                      title={link.description}
                      href={link.href || link.url}
                      linkToURL={link?.url?.linkToURL}
                      newTab
                      noFollow={link?.url?.noFollow}
                      css={`
                        transition: all 0.2s ease;
                        &:hover {
                          opacity: 0.5;
                          transform: translateY(-2px);
                        }
                      `}
                    >
                      <Icons
                        name={link.icon}
                        title={link.icon}
                        fill="text01"
                        height="24px"
                        role="img"
                        aria-label={open + " " + link.icon}
                      />
                    </LinkWrapper>
                  )
                })}
            </Inline>
          </Flex>
          {/* Languages */}
          <Flex>
            {pageLanguages?.length > 1 && languageNames && (
              <Stack hs="xs" alignItems="center">
                {pageLanguages.map((lang, idx) => {
                  const isOtherLanguage = languageId !== lang.languageId
                  const LinkText = () => (
                    <Text
                      display="flex"
                      textStyle="tinyxs"
                      color="text01"
                      style={isOtherLanguage ? { fontWeight: "bold" } : {}}
                      css={`
                        &::before {
                          display: block;
                          content: ${idx > 0 ? '""' : "unset"};
                          float: left;
                          margin-right: 16px;
                          width: 1px;
                          height: 10px;
                          background-color: ${theme("colors.text03")};
                          align-self: center;
                        }
                      `}
                    >
                      {languageNames[lang?.locale]}
                    </Text>
                  )
                  return isOtherLanguage ? (
                    <LinkWrapper
                      key={idx}
                      href={lang.url}
                      css={`
                        &:hover {
                          opacity: 0.75;
                        }
                      `}
                    >
                      <LinkText />
                    </LinkWrapper>
                  ) : (
                    <LinkText key={idx} />
                  )
                })}
              </Stack>
            )}
          </Flex>
        </motion.div>
      )}
    </Nav>
  )
}
export default NavContainer
