import { useContext } from "react"
import { SiteContext } from "@contexts"

function useRenderer() {
  const { renderer } = useContext(SiteContext)
  return {
    isSSG: renderer === "ssg",
    isEditor: renderer === "editor",
    isAX: renderer === "editor" || renderer === "preview",
    isStorybook: renderer === "storybook",
  }
}

export { useRenderer }
