import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"

export default {
  schemaType: "component",
  displayName: "Positions Card",
  component: "PositionsCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Tag text",
          type: "TextField",
          key: "tag",
          mandatory: true,
          humanReadable: true,
        },
        {
          title: "Image",
          type: "ImageField",
          key: "image",
          mandatory: true,
        },
      ],
    },
  ],
  default: {
    component: "PositionsCard",
    tag: "Tag text",
    image: DEFAULT_IMAGE_FIELD,
  },
}
