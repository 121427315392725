import AccordionElement from "./AccordionElement"
import AddressCard from "./AddressCard"
import AddToCalendar from "./AddToCalendar"
import Advantage from "./Advantage"
import AnchorLink from "./AnchorLink"
import AudiovisualCard from "./AudiovisualCard"
import BasicBoxedCard from "./BasicBoxedCard"
import BasicCard from "./BasicCard"
import BasicIconCard from "./BasicIconCard"
import BlockTitle from "./BlockTitle"
import BlogCard from "./BlogCard"
import BluePanel from "./BluePanel"
import Chart from "./Chart"
import CTACard from "./CTACard"
import CypherCard from "./CypherCard"
import DescriptionButton from "./DescriptionButton"
import DownloadDocument from "./DownloadDocument"
import DownloadFormComponent from "./DownloadFormComponent"
import EventCard from "./EventCard"
import ExternalEventCard from "./ExternalEventCard"
import FaqElement from "./FaqElement"
import FeaturedProgramCard from "./FeaturedProgramCard"
import FeaturedText from "./FeaturedText"
import File from "./File"
import FinalStep from "./FinalStep"
import FormButton from "./FormButton"
import HeroCard from "./HeroCard"
import HeroMosaicCard from "./HeroMosaicCard"
import HeroSideCard from "./HeroSideCard"
import HorizontalTabElement from "./HorizontalTabElement"
import IconCard from "./IconCard"
import IconCenteredCard from "./IconCenteredCard"
import InsightsCard from "./InsightsCard"
import IntroFormElement from "./IntroFormElement"
import Image from "./Image"
import ImageCard from "./ImageCard"
import ImageStep from "./ImageStep"
import ImageWithoutVeil from "./ImageWithoutVeil"
import InternalEventCard from "./InternalEventCard"
import Link from "./Link"
import LinkModal from "./LinkModal"
import FloatingCTAElement from "./FloatingCTAElement"
import LinkableImage from "./LinkableImage"
import Logo from "./Logo"
import LogoCard from "./LogoCard"
import Map from "./Map"
import MapCardBox from "./MapCardBox"
import MasonryCard from "./MasonryCard"
import Milestone from "./Milestone"
import MilestoneAudiovisual from "./MilestoneAudiovisual"
import MilestoneClaim from "./MilestoneClaim"
import MilestoneCypher from "./MilestoneCypher"
import MilestoneSlide from "./MilestoneSlide"
import MissingComponentMessage from "./MissingComponentMessage"
import NewsCard from "./NewsCard"
import PaginationCard from "./PaginationCard"
import PersonCard from "./PersonCard"
import PositionsCard from "./PositionsCard"
import PrinciplesCard from "./PrinciplesCard"
import Profile from "./Profile"
import ProgramCategory from "./ProgramCategory"
import ProgramDescription from "./ProgramDescription"
import ProgramTabElement from "./ProgramTabElement"
import PublicationCard from "./PublicationCard"
import QuickFact from "./QuickFact"
import QuickFactStories from "./QuickFactStories"
import QuickFactElement from "./QuickFactElement"
import Quote from "./Quote"
import QuoteSmall from "./QuoteSmall"
import ScholarshipCard from "./ScholarshipCard"
import Slide from "./Slide"
import SlimSocialShare from "./SlimSocialShare"
import StepCard from "./StepCard"
import StepTabElement from "./StepTabElement"
import StepTabs from "./StepTabs"
import StoryCard from "./StoryCard"
import StructureTabElement from "./StructureTabElement"
import GeneralInfoCard from "./GeneralInfoCard"
import TabSlide from "./TabSlide"
import TableColumn from "./TableColumn"
import TableRow from "./TableRow"
import Tag from "./Tag"
import TagElement from "./TagElement"
import TextStep from "./TextStep"
import TextualPanel from "./TextualPanel"
import ValueCard from "./ValueCard"
import VerticalSlide from "./VerticalSlide"
import VerticalTabElement from "./VerticalTabElement"
import Video from "./Video"
import VideoButton from "./VideoButton"
import VideoTextCard from "./VideoTextCard"
import VideoTitle from "./VideoTitle"
import ProjectCard from "./ProjectCard"
import MultiPageElement from "./MultiPageElement"

export default {
  AccordionElement,
  AddressCard,
  AddToCalendar,
  Advantage,
  AnchorLink,
  AudiovisualCard,
  BasicBoxedCard,
  BasicCard,
  BasicIconCard,
  BlockTitle,
  BlogCard,
  BluePanel,
  Chart,
  CTACard,
  CypherCard,
  DescriptionButton,
  DownloadDocument,
  DownloadFormComponent,
  EventCard,
  ExternalEventCard,
  FaqElement,
  FeaturedProgramCard,
  FeaturedText,
  File,
  FinalStep,
  FormButton,
  HeroCard,
  HeroMosaicCard,
  HeroSideCard,
  HorizontalTabElement,
  IconCard,
  IconCenteredCard,
  InsightsCard,
  IntroFormElement,
  Image,
  ImageCard,
  ImageStep,
  ImageWithoutVeil,
  InternalEventCard,
  Link,
  LinkModal,
  FloatingCTAElement,
  LinkableImage,
  Logo,
  LogoCard,
  Map,
  MapCardBox,
  MasonryCard,
  Milestone,
  MilestoneAudiovisual,
  MilestoneClaim,
  MilestoneCypher,
  MilestoneSlide,
  MissingComponentMessage,
  NewsCard,
  PaginationCard,
  PersonCard,
  PositionsCard,
  Profile,
  PrinciplesCard,
  ProgramCategory,
  ProgramDescription,
  ProgramTabElement,
  PublicationCard,
  QuickFact,
  QuickFactStories,
  QuickFactElement,
  Quote,
  QuoteSmall,
  ScholarshipCard,
  Slide,
  SlimSocialShare,
  StepCard,
  StepTabElement,
  StepTabs,
  StoryCard,
  StructureTabElement,
  GeneralInfoCard,
  TabSlide,
  TableColumn,
  TableRow,
  Tag,
  TagElement,
  TextStep,
  TextualPanel,
  ValueCard,
  VerticalSlide,
  VerticalTabElement,
  Video,
  VideoButton,
  VideoTextCard,
  VideoTitle,
  ProjectCard,
  MultiPageElement,
}
