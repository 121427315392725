import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { HeroImage, HeroImage2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Hero Image",
  component: "HeroImage",
  category: "hero",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          type: "UniqueCheck",
          key: "noShowTitle",
          options: [
            {
              title: "Don't show title",
            },
          ],
        },
        {
          title: "Subtitle",
          type: "TextField",
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Background Image",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "image",
          helptext: "Recommended minimum image size: 2508x1030",
        },
        {
          title: "Logos",
          key: "logos",
          type: "ComponentArray",
          maxItems: null,
          whiteList: ["Logo"],
        },
        {
          title: "CTA 1",
          type: "ComponentContainer",
          whiteList: ["DescriptionButton"],
          key: "cta1",
          hideable: true,
        },
        {
          title: "CTA 2",
          type: "ComponentContainer",
          whiteList: ["DescriptionButton"],
          key: "cta2",
          hideable: true,
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "HeroImage",
    title: { content: "Title", tag: "h1" },
    noShowTitle: false,
    subtitle: "Lorem Ipsum",
    image: {
      component: "Image",
      ...DEFAULT_IMAGE_COMPONENT,
    },
    cta1: {
      component: "DescriptionButton",
    },
    cta2: {
      component: "DescriptionButton",
    },
    logos: [],
  },
  thumbnails: {
    "1x": HeroImage,
    "2x": HeroImage2x,
  },
}
