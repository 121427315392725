import _components from "@components"
import modules from "@modules"

import browser from "../builder.browser"
import ssr from "../builder.ssr"

// ADAPTED CORE ELEMS
import { mapLibrary, SiteProvider } from "@adapters"

// TEMPLATES
import * as templates from "@templates"

const components = mapLibrary({ ..._components, ...modules })

const ONE_TRUST_ID = "f3490e9b-e3c6-4897-bcee-29b61d0d6460"
const ONE_TRUST_ID_IENYC = "01914556-fbc9-7d1a-be41-9957bf7de126"
const IENYC_DOMAINS = [
  "www.ienyc.edu",
  "ienyc.edu",
  "landing.ienyc.edu",
  "www.qa.thesaurus.ienyc.edu",
  "qa.thesaurus.ienyc.edu",
  "landing.qa.thesaurus.ienyc.edu",
]

function changeScript() {
  if (typeof window !== "undefined") {
    const currentDomain =
      typeof window !== "undefined" &&
      window?.location &&
      window?.location?.hostname

    const script = document.createElement("script")
    script.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
    script.type = "text/javascript"
    script.async = true
    script.rel = "preconnect"
    script.setAttribute(
      "data-domain-script",
      IENYC_DOMAINS.includes(currentDomain) ? ONE_TRUST_ID_IENYC : ONE_TRUST_ID
    )

    document.head.appendChild(script)
  }
}

function setNorCookie() {
  if (typeof window !== "undefined") {
    // set cookie to know if the user is new or recurrent
    if (!sessionStorage.getItem("prevNor")) {
      if (document.cookie.includes("nor=")) {
        var norValue = "r"
      } else {
        var norValue = "n"
      }
      const norExpires = new Date()
      norExpires.setTime(norExpires.getTime() + 360 * 24 * 60 * 60 * 1000)
      document.cookie =
        "nor=" + norValue + "; expires=" + norExpires + "; path=/"
      sessionStorage.setItem("prevNor", true)
    }
  }
}

changeScript()
setNorCookie()

//Dimensions
const generateAutomaticDimensions = props => ({})

export {
  templates,
  SiteProvider,
  components,
  ssr,
  browser,
  generateAutomaticDimensions,
}
