export default {
  schemaType: "component",
  displayName: "Program Tab Element",
  component: "ProgramTabElement",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          key: "title",
          type: "TextField",
          humanReadable: true,
        },
        {
          title: "Section Links",
          type: "ComponentArray",
          whiteList: ["Link"],
          key: "elements",
        },
        {
          title: "Main Component",
          type: "ComponentArray",
          mandatory: true,
          whiteList: [
            "Accordion",
            "Accordion50",
            "AddressCollection",
            "ArticlesDistributor",
            "AudiovisualDistributor",
            "BasicContent",
            "BasicWysiwyg",
            "BlogDistributor",
            "CTACollection",
            "CardCollection",
            "ChartCollection",
            "Countdown",
            "CypherCollection",
            "DocumentDownload",
            "DownloadCollection",
            "DownloadForm",
            "EpicAwardsDistributor",
            "EventsDistributor",
            "FeaturedAudiovisual",
            "FeaturedBluePanel",
            "FeaturedBlock",
            "FeaturedProgram",
            "FeaturedTextBluePanels",
            "FeedbackForm",
            "FloatingCTA",
            "FullWidthForm",
            "GeneralInfoDistributor",
            "HorizontalTabs",
            "Iframe",
            "IframeQuiz",
            "ImageGallery",
            "InformationStructure",
            "InformativeHeader",
            "InsightsDistributor",
            "Intro",
            "IntroForm",
            "IntroTextCard",
            "ListCollapsed",
            "LoadScript",
            "LocationMap",
            "LogoCollection",
            "MasonryCollection",
            "Milestones",
            "NewsDistributor",
            "PersonDistributor",
            "PopUp",
            "Positions",
            "Principles",
            "ProfileCollection",
            "ProgramDistributor",
            "ProgramFinder",
            "ProgramsPresentation",
            "ProgramsPresentationAuto",
            "ProjectDistributor",
            "PublicationDistributor",
            "RequestInfo",
            "RequestInfoCollection",
            "RequestInfoWithModal",
            "ScholarshipsDistributor",
            "ScrollIndicator",
            "SearchModule",
            "SimpleFloatingCTA",
            "Slider",
            "SocialMedia",
            "SocialShare",
            "Spacer",
            "SpecializationDistributor",
            "StoriesDistributor",
            "StepsCollection",
            "StudentPath",
            "StudyPlan",
            "Table",
            "TagDistributor",
            "TypographicVideo",
            "Testimonial",
            "Timeline",
            "VerticalSlider",
            "VerticalTabs",
            "VideoCollection",
            "VideoGallery",
            "VideoPlaylist",
            "WebinarCollection",
            "Wysiwyg",
          ],
          key: "componentModules",
        },
      ],
    },

    {
      title: "Seo",
      fields: [
        {
          title: "Section Slug",
          key: "sectionSlug",
          type: "TextField",
          humanReadable: true,
        },
        {
          title: "Meta title",
          key: "metaTitle",
          type: "TextField",
          humanReadable: true,
        },
        {
          title: "Meta description",
          key: "metaDescription",
          type: "TextField",
          humanReadable: true,
        },
      ],
    },
  ],
  default: {
    component: "ProgramTabElement",
    title: "Page title",
    elements: [],
    sectionSlug: "tab-01",
    metaTitle: "The meta title",
    metaDescription: "The meta description",
    componentModules: [],
  },
}
